<template>
  <div>
    <PullRefresh v-model="refreshing" @refresh="onRefresh">
      <List
        ref="assetsList"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <ListTransition>
          <AssetsCard
            v-for="(v, k) in assets"
            :key="k"
            :data-index="k"
            :data-page-size="pageSize"
            :value="rectifyMaps(v)"
            @click.native="showDetail(v)"
          ></AssetsCard>
        </ListTransition>
      </List>
    </PullRefresh>
    <div v-if="finished" class="lcf-common-bottom-tip">
      市场有风险，出借需谨慎
    </div>
  </div>
</template>

<script>
import { PullRefresh, List, Notify } from 'vant'
import { mapActions } from 'vuex'
import AssetsCard from './AssetsCard.vue'

export default {
  name: 'AssetsList',
  props: ['value'],
  components: {
    PullRefresh,
    List,
    AssetsCard,
  },
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      toPage: 1,
      pageSize: 10,
      totalPage: null,

      assets: [],
    }
  },
  mounted() {
    // let unwatch = this.$watch('value', function (newVal, oldVal) {
    //   let p1 = ['holding', 'finished'],
    //     p2 = ['zhitou', 'zhaizhuan']
    //   if (
    //     (p1.includes(newVal) && p2.includes(oldVal)) ||
    //     (p2.includes(newVal) && p1.includes(oldVal))
    //   ) {
    //     this.assets = []
    //     this.resetLoadingStatus()
    //     this.resetTopage()
    //   }
    // })

    this.$once('hook:beforeDestroy', function () {
      this.assets = []
      // unwatch()
    })
  },
  methods: {
    ...mapActions(['requestMyAssets', 'requestOldInvestRecord']),
    async showAssets() {
      let assets = []
      let error, data
      let statusMap = {
        holding: 1,
        finished: 2,
      }
      let history = {
        zhitou: 1,
        zhaizhuan: 2,
      }

      if (history[this.value]) {
        ;[error, data] = await to(
          this.requestOldInvestRecord({
            page: this.toPage,
            pageSize: this.pageSize,
            type: history[this.value],
          })
        )
      } else {
        ;[error, data] = await to(
          this.requestMyAssets({
            page: this.toPage,
            pageSize: this.pageSize,
            status: statusMap[this.value],
          })
        )
      }

      if (error) {
        Notify({
          type: 'warning',
          message: error.message || error,
        })
      }

      if (data && data.code === -1) {
        assets = data.data
        this.totalPage = data.total
      }
      return assets
    },

    showDetail(v) {
      if (['holding', 'finished'].includes(this.value)) {
        setTimeout(() => {
          this.$router.push({
            name: 'AssetsDetail',
            params: { assets_id: v.id },
          })
        }, 300)
      }
    },
    async onLoad(reloadData) {
      if (this.toPage > this.totalPage && this.totalPage != null) {
        this.finished = true
        return
      }

      let error, assets
      ;[error, assets] = await to(this.showAssets(this.toPage))

      if (error) {
        this.refreshing = false
        this.loading = false
        this.error = true
        return
      }

      if (reloadData === true) {
        this.assets.length = 0
      }
      this.assets.push(...assets)

      this.toPage++
      this.loading = false
      this.refreshing = false
    },
    onRefresh() {
      let reloadData = true

      this.toPage = 1

      this.finished = false

      this.loading = true
      this.onLoad(reloadData)
    },
    resetLoadingStatus() {
      this.loading = false
      this.finished = false
      this.refreshing = false
      this.error = false
    },
    resetTopage() {
      this.toPage = 1
    },
  },
  computed: {
    rectifyMaps() {
      return (source) => {
        let history = {
          zhitou: 1,
          zhaizhuan: 2,
        }
        if (history[this.value]) {
          return Object.assign(source, {
            end_time: source.time_end,
            available_amount: source.amount,
          })
        }
        return source
      }
    },
  },
}
</script>
