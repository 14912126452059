<template>
  <div class="lcf-card" v-waves @click="() => {}">
    <div class="lcf-card-head">
      <span class="lcf-card-head--part1">
        <span class="van-ellipsis" v-show="value.period_type != null">{{
          ['长期', '中期', '短期'][value.period_type - 1]
        }}</span>
      </span>
      <span class="lcf-card-head--part2 van-ellipsis">
        {{ value.name }}
      </span>
      <span class="lcf-card-head--part3 van-ellipsis">{{ value.status }}</span>
    </div>

    <div class="lcf-card-body">
      <span class="lcf-card-body--part1">
        <span>
          <span>{{ value.apr }}</span>
          <span>%</span>
        </span>
        <span>约定年化借款利率</span>
      </span>
      <span class="lcf-card-body--part2">
        <span>{{ Math.floor(value.available_amount) }}</span>
        <span>持有金额（元）</span>
      </span>
      <span class="lcf-card-body--part3">
        <span>{{ value.days }}</span>
        <span>剩余期限（天）</span>
      </span>
    </div>
    <div class="van-hairline--top" style="width: 90%; left: 5%"></div>
    <div class="lcf-card-foot">
      <span class="lcf-card-foot--part1" v-show="value.addtime != null">
        {{ time(value.addtime) }}加入
      </span>
      <span class="lcf-card-foot--part2" v-show="value.end_time != null">
        {{ time(value.end_time) }}到期
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LcfAssetsCard',
  props: ['value'],
  computed: {
    time() {
      return (time) => {
        let ret = ''
        try {
          let date = new Date(time)
          let year = date.getFullYear(),
            month = date.getMonth() + 1,
            day = date.getDate()
          if ([year, month, day].some((x) => isNaN(x))) {
            throw ''
          }

          ret =
            `${year}-` +
            `${month}`.padStart(2, 0) +
            '-' +
            `${day}`.padStart(2, 0)
        } catch (e) {
          //
        }
        return ret
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.lcf-card {
  background: #fff;
  margin: 16px 24px 0 24px;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: 0 0 8px -1px rgba($color: #000000, $alpha: 0.2);
  .lcf-card-head {
    display: flex;
    flex-flow: row nowrap;
    height: 12px;
    font-size: 12px;
    line-height: 12px;
    padding: 16px;
    .lcf-card-head--part1 {
      flex: 1 1;
      span {
        color: $lcf-color-red;
        padding: 0 8px;
        position: relative;
        background: $lcf-color-red-light;
        border-radius: 2px;
        &::after,
        &::before {
          left: 100%;
          top: 50%;
          border: solid transparent;
          content: ' ';
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }
        &::after {
          border-color: transparent;
          border-left-color: $lcf-color-red-light;
          border-width: 3px;
          margin-top: -3px;
        }
        &::before {
          border-color: transparent;
          border-left-color: $lcf-color-red-light;
          border-width: 3px;
          margin-top: -3px;
        }
      }
    }
    .lcf-card-head--part2 {
      flex: 3 1;
    }
    .lcf-card-head--part3 {
      flex: 1 1;
      color: $lcf-color-red;
      text-align: right;
    }
  }

  .lcf-card-body {
    display: flex;
    flex-flow: row nowrap;
    padding: 0 16px 8px 16px;
    .lcf-card-body--part1 {
      flex: 8 1;
      display: flex;
      flex-flow: column nowrap;
      span {
        &:first-child {
          font-size: 24px;
          span:last-child {
            color: #000;
            font-size: 12px;
          }
        }
        &:last-child {
          font-size: 12px;
          color: $lcf-color-text-gray;
          white-space: nowrap;
        }
      }
    }
    .lcf-card-body--part2 {
      flex: 7 1;
      display: flex;
      flex-flow: column nowrap;
      span {
        &:first-child {
          font-size: 24px;
        }
        &:last-child {
          font-size: 12px;
          color: $lcf-color-text-gray;
        }
      }
    }
    .lcf-card-body--part3 {
      flex: 7 1;
      display: flex;
      flex-flow: column nowrap;
      text-align: right;
      span {
        &:first-child {
          font-size: 24px;
        }
        &:last-child {
          font-size: 12px;
          color: $lcf-color-text-gray;
        }
      }
    }
  }

  .lcf-card-foot {
    display: flex;
    flex-flow: row nowrap;
    font-size: 12px;
    line-height: 12px;
    height: 12px;
    padding: 8px 16px 8px 16px;
    .lcf-card-foot--part1 {
      flex: 1 1;
    }
    .lcf-card-foot--part2 {
      flex: 1 1;
      text-align: right;
    }
  }
}
</style>
