<template>
  <div>
    <div class="lcf-nav-bar">
      <NavBar
      :title="isHistory ? '资产历史记录' : '我的资产'"
      left-arrow
      fixed
      @click-left="$router.replace({name: 'User'})"
      safe-area-inset-top
    ></NavBar>
    </div>
    <Tabs v-model="active" sticky animated offset-top="44px">
      <Tab :title="isHistory ? '直投项目' : '持有中'">
        <div style="height: 48px; width: 100%;"></div>
        <AssetsList key="al-1" :value="isHistory ? 'zhitou' : 'holding'" />
      </Tab>
      <Tab :title="isHistory ? '债转项目' : '已完成'">
      <div style="height: 48px; width: 100%;"></div>
        <AssetsList key="al-2" :value="isHistory ? 'zhaizhuan' : 'finished'" />
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import { NavBar, Tabs, Tab } from 'vant'
import AssetsList from './AssetsList.vue'

export default {
  name: 'MyAssets',
  props: ['status'],
  components: {
    NavBar,
    Tabs,
    Tab,
    AssetsList,
  },
  data() {
    return {
      active: 0,
    }
  },
  computed: {
    isHistory() {
      return this.status === 'history'
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .van-hairline--bottom::after {
  border-bottom-width: 0;
}

.lcf-nav-bar {
  height: 48px;
  position: absolute;
  top: 0;
}
</style>
